import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LandingPage from "./components/LandingPage";
import Configuration from "./components/Configuration";
import MasterDashboard from "./components/MasterDashboard";
import SystemDashboard from "./components/SystemDashboard";
import Reports from "./components/Reports";
import SignInPage from "./components/SignIn";
import Logs from "./components/Logs";
import SystemLogs from "./components/SystemLogs";
import AddCustomer from "./components/AddCustomer";
import AddLicense from "./components/AddLicense";
import { DashboardProvider } from "./contexts/DashboardContext";
import "./App.css";

function App() {
  return (
    <DashboardProvider>
      <div className="App">
        <Router>
          <Routes>
            <Route path="/" exact element={<LandingPage />} />
            <Route path="/configuration" element={<Configuration />} />
            <Route path="/dashboard" element={<MasterDashboard />} />
            <Route path="/systemdashboard" element={<SystemDashboard />} />
            <Route
              path="/systemdashboard/:agent"
              element={<SystemDashboard />}
            />
            <Route path="/reports" element={<Reports />} />
            <Route path="/signin" element={<SignInPage />} />
            <Route path="/logs/systems/:group" element={<Logs />} />
            <Route
              path="/logs/node/:node/:group/:timeframe"
              element={<SystemLogs />}
            />
            <Route path="/addcustomer" element={<AddCustomer />} />
            <Route path="/addlicense" element={<AddLicense />} />
          </Routes>
        </Router>
      </div>
    </DashboardProvider>
  );
}

export default App;
