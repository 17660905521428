import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import logoheader from "../Images/logo-white.webp";
import { useDashboard } from "../contexts/DashboardContext";
import "../App.css";

const UserNavbar = () => {
  const navigate = useNavigate();
  const username = Cookies.get("username");
  const role = Cookies.get("role");
  const { admin, setAdmin } = useDashboard();

  return (
    <>
      <div className="navi">
        <img
          src={logoheader}
          className="logo1"
          onClick={() => navigate("/")}
        ></img>
        <ul>
          {role === "1" ? (
            <li>
              <li>
                <a onClick={() => navigate("/dashboard")}>Home</a>
              </li>
            </li>
          ) : (
            <>
              <li>
                <a onClick={() => navigate("/dashboard")}>Dashboard</a>
              </li>
              <li>
                <a onClick={() => navigate("/systemdashboard")}>System View</a>
              </li>
              <li>
                <a onClick={() => navigate("/reports")}>Reports</a>
              </li>
              <li>
                <a onClick={() => navigate("/configuration")}>Configuration</a>
              </li>
            </>
          )}
        </ul>
        <div className="right_nav">
          <ul>
            <li>
              <a href="#">
                <i className="fa-solid fa-user"></i> &nbsp; {username} &nbsp;
                <i className="fa-solid fa-caret-down"></i>
              </a>
              <ul>
                <li>
                  <a
                    onClick={() => {
                      Cookies.remove("token");
                      Cookies.remove("customerid");
                      Cookies.remove("username");
                      Cookies.remove("customerkey");
                      Cookies.remove("role");
                      setAdmin(false);
                      navigate("/signin");
                    }}
                  >
                    Sign Out
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default UserNavbar;
