import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import UserNavbar from "./UserNavbar";
import { useNavigate } from "react-router-dom";
import { useDashboard } from "../contexts/DashboardContext";
import "../App.css";

const AddLicense = () => {
  const navigate = useNavigate();
  const token = Cookies.get("token");
  const role = Cookies.get("role");
  const { setAdmin } = useDashboard();
  const [id, setId] = useState("");
  const [type, setType] = useState("");
  const { customers, setCustomers, licenses, setLicenses } = useDashboard();
  const [credentials, setCredentials] = useState({
    type: "",
    count: "",
    customerid: "",
    expiry: "",
  });
  const [error, setError] = useState("");

  useEffect(() => {
    if (token && role) {
      if (role === "1") {
        setAdmin(true);
      }
    }
  }, []);

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { count, expiry } = credentials;
    axios
      .post(
        "/api/Customer/licenses",
        {
          type,
          count,
          customerid: id,
          expiry,
        },
        {
          headers: {
            "x-auth-token": token,
          },
        }
      )
      .then((res) => {
        setCredentials({
          type: "",
          count: "",
          customerid: "",
          expiry: "",
        });
        navigate("/dashboard");
      })
      .catch(() => {
        setError("Invalid Data");
        setTimeout(() => {
          setError("");
        }, 1000);
      });
  };
  useEffect(() => {
    if (!token) {
      navigate("/");
    } else {
      axios
        .get("/api/customer", {
          headers: {
            "x-auth-token": token,
          },
        })
        .then((res) => {
          setCustomers(res.data.customers);
        })
        .catch(() => {
          navigate("/");
        });
    }
  }, []);

  useEffect(() => {
    if (!token) {
      navigate("/");
    } else {
      axios
        .get("/api/License", {
          headers: {
            "x-auth-token": token,
          },
        })
        .then((res) => {
          setLicenses(res.data.data);
        })
        .catch(() => {
          navigate("/dashboard");
        });
    }
  }, []);

  const handleSystemId = (e) => {
    e.preventDefault();
    setId(e.target.value);
  };

  const handleType = (e) => {
    e.preventDefault();
    setType(e.target.value);
  };

  return (
    <>
      <UserNavbar />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <div className="form-2-parent">
          <h2 style={{ textAlign: "center" }}>Add License</h2>

          <form className="form-2" onSubmit={handleSubmit}>
            <select className="license" value={type} onChange={handleType}>
              <option value="">Select License</option>
              {licenses.map((license, i) => {
                return (
                  <option key={i} value={license.type}>
                    {license.name}
                  </option>
                );
              })}
            </select>
            <br />
            <input
              type="number"
              placeholder="Count"
              name="count"
              id="count"
              min="1"
              value={credentials.count}
              onChange={handleChange}
              required
            ></input>
            <br />
            <select className="license" value={id} onChange={handleSystemId}>
              <option value="">Select Customer</option>
              {customers.map((customer, i) => {
                return (
                  <option key={i} value={customer.value}>
                    {customer.label}
                  </option>
                );
              })}
            </select>
            <br />
            <input
              type="text"
              placeholder="Expiry date"
              name="expiry"
              id="expiry"
              onFocus={(e) => {
                e.currentTarget.type = "date";
                e.currentTarget.min = new Date().toISOString().split("T")[0];
              }}
              value={credentials.expiry}
              onChange={handleChange}
              required
            ></input>
            {error}
            <br />
            <br />
            <a onClick={() => navigate("/dashboard")}>
              <button
                type="button"
                className="btn"
                style={{
                  float: "left",
                  background: "grey",
                  border: "none",
                  padding: "0.5rem",
                  cursor: "pointer",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                Cancel
              </button>
            </a>
            <button
              type="submit"
              className="btn"
              style={{
                float: "right",
                padding: "0.5rem",
                cursor: "pointer",
                background: "#030B3A",
                border: "none",
                color: "white",
                fontWeight: "bold",
              }}
            >
              Add License
            </button>
            <br />
          </form>
        </div>
      </div>
    </>
  );
};

export default AddLicense;
