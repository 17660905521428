import React, { useEffect, useState } from "react";
import UserNavbar from "./UserNavbar";
import Cookies from "js-cookie";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDashboard } from "../contexts/DashboardContext";
import {
  PieChart,
  Pie,
  Legend,
  Tooltip,
  Cell,
  CartesianGrid,
  XAxis,
  YAxis,
  AreaChart,
  Area,
} from "recharts";
import { MapContainer, TileLayer, Circle } from "react-leaflet";
import "../App.css";
import "leaflet/dist/leaflet.css";

const MasterDashboard = () => {
  const [systemsData, setSystemsData] = useState([]);
  const [totalAgentsCount, setTotalAgentsCount] = useState(0);
  const [activeAgentsCount, setActiveAgentsCount] = useState(0);
  const [gt10AlertsCount, setGt10AlertsCount] = useState(0);
  const [rootkitAlertsCount, setRootkitAlertsCount] = useState(0);
  const [fiimAlertsCount, setFiimAlertsCount] = useState(0);
  const [attackAlertscount, setAttackAlertsCount] = useState(0);
  const [byAgentData, setByAgentData] = useState([]);
  const [byGroupData, setByGroupData] = useState([]);
  const [timeSeriesData, setTimeSeriesData] = useState([]);
  const [agentsInfo, setAgentsInfo] = useState([]);
  const [geoLocationData, setGeoLocationData] = useState([]);
  const { admin, setAdmin } = useDashboard();
  const token = Cookies.get("token");
  const customerid = Cookies.get("customerid");
  const role = Cookies.get("role");
  const navigate = useNavigate();

  useEffect(() => {
    if (token && role) {
      if (role === "1") {
        setAdmin(true);
      }
    }
  }, []);

  useEffect(() => {
    if (!token) {
      navigate("/signin");
    } else {
      axios
        .get("/api/token/isLoggedIn", {
          headers: {
            "x-auth-token": token,
          },
        })
        .then((res) => console.log(res.data))
        .catch(() => navigate("/signin"));
    }
  }, []);

  useEffect(() => {
    if (role === "3") {
      axios
        .get(`/api/system/${customerid}`, {
          headers: {
            "x-auth-token": token,
          },
        })
        .then((res) => setSystemsData(res.data.systems));
    }
  }, []);

  useEffect(() => {
    if (role === "3") {
      axios
        .get(`/api/system/gettotalagentscount`, {
          headers: {
            "x-auth-token": token,
          },
        })
        .then((res) => setTotalAgentsCount(res.data));
    }
  }, []);

  useEffect(() => {
    if (role === "3") {
      axios
        .get(`/api/system/getactiveagentscount`, {
          headers: {
            "x-auth-token": token,
          },
        })
        .then((res) => setActiveAgentsCount(res.data));
    }
  }, []);

  useEffect(() => {
    if (role === "3") {
      axios
        .get(`/api/system/systems/type/${customerid}/gt10`, {
          params: {
            hours: 24,
          },
          headers: {
            "x-auth-token": token,
          },
        })
        .then((res) => setGt10AlertsCount(res.data));
    }
  }, []);

  useEffect(() => {
    if (role === "3") {
      axios
        .get(`/api/system/systems/type/${customerid}/rootcheck`, {
          params: {
            hours: 24,
          },
          headers: {
            "x-auth-token": token,
          },
        })
        .then((res) => setRootkitAlertsCount(res.data));
    }
  }, []);

  useEffect(() => {
    if (role === "3") {
      axios
        .get(`/api/system/systems/type/${customerid}/syscheck`, {
          params: {
            hours: 24,
          },
          headers: {
            "x-auth-token": token,
          },
        })
        .then((res) => setFiimAlertsCount(res.data));
    }
  }, []);

  useEffect(() => {
    if (role === "3") {
      axios
        .get(`/api/system/systems/type/${customerid}/attack`, {
          params: {
            hours: 24,
          },
          headers: {
            "x-auth-token": token,
          },
        })
        .then((res) => setAttackAlertsCount(res.data));
    }
  }, []);

  useEffect(() => {
    if (role === "3") {
      axios
        .get(`/api/system/systems/group/${customerid}/byagent`, {
          params: {
            hours: 24,
          },
          headers: {
            "x-auth-token": token,
          },
        })
        .then((res) => setByAgentData(res.data));
    }
  }, []);

  useEffect(() => {
    if (role === "3") {
      axios
        .get(`/api/system/systems/group/${customerid}/bygroup`, {
          params: {
            hours: 24,
          },
          headers: {
            "x-auth-token": token,
          },
        })
        .then((res) => setByGroupData(res.data));
    }
  }, []);

  useEffect(() => {
    if (role === "3") {
      axios
        .get(`/api/system/systems/timeseriesbyagents/${customerid}`, {
          params: {
            hours: 24,
          },
          headers: {
            "x-auth-token": token,
          },
        })
        .then((res) => setTimeSeriesData(res.data));
    }
  }, []);

  useEffect(() => {
    if (role === "3") {
      axios
        .get(`/api/system/systems/map/${customerid}`, {
          params: {
            hours: 24,
          },
          headers: {
            "x-auth-token": token,
          },
        })
        .then((res) => setGeoLocationData(res.data));
    }
  }, []);

  useEffect(() => {
    if (role === "3") {
      axios
        .get(`/api/system/agentsinfo`, {
          headers: {
            "x-auth-token": token,
          },
        })
        .then((res) => setAgentsInfo(res.data));
    }
  }, []);

  const COLORS = [
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#FF8042",
    "#FF6633",
    "#FFB399",
    "#FF33FF",
    "#FFFF99",
    "#00B3E6",
    "#E6B333",
    "#3366E6",
    "#999966",
    "#99FF99",
    "#B34D4D",
    "#80B300",
    "#809900",
    "#E6B3B3",
    "#6680B3",
    "#66991A",
    "#FF99E6",
    "#CCFF1A",
    "#FF1A66",
    "#E6331A",
    "#33FFCC",
    "#66994D",
    "#B366CC",
    "#4D8000",
    "#B33300",
    "#CC80CC",
    "#66664D",
    "#991AFF",
    "#E666FF",
    "#4DB3FF",
    "#1AB399",
    "#E666B3",
    "#33991A",
    "#CC9999",
    "#B3B31A",
    "#00E680",
    "#4D8066",
    "#809980",
    "#E6FF80",
    "#1AFF33",
    "#999933",
    "#FF3380",
    "#CCCC00",
    "#66E64D",
    "#4D80CC",
    "#9900B3",
    "#E64D66",
    "#4DB380",
    "#FF4D4D",
    "#99E6E6",
    "#6666FF",
  ];

  const RADIAN = Math.PI / 180;

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
        fontSize="0.90rem"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const position = [8.7832, 34.5085];

  function MultipleMarkers() {
    return geoLocationData.map((coordinata, index) => {
      return (
        <Circle
          key={index}
          center={coordinata.coordinates}
          fillColor="green"
          radius={coordinata.doc_count * 50}
          weight={1}
          color="green"
        />
      );
    });
  }

  const chartData = timeSeriesData.flatMap(({ key, timestamp }) =>
    timestamp.map(({ key: time, doc_count }) => ({
      time: new Date(time).toLocaleString(),
      [key]: doc_count,
    }))
  );

  const MasterDashboardDiv = (
    <>
      <h1 style={{ fontSize: "2rem", padding: "1rem 2rem" }}>Overview</h1>
      <div className="masterdashboard">
        <div style={{ display: "flex" }}>
          <div className="agentmetrics" style={{ display: "flex" }}>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "0.5rem",
                }}
              >
                Total Systems
              </div>
              <div className="totalagents">{totalAgentsCount}</div>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "0.5rem",
                }}
              >
                Systems Online
              </div>
              <div className="activeagents">{activeAgentsCount}</div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              border: "1px solid black",
              height: "max-content",
              margin: "1rem",
              padding: "1rem 0",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "0.5rem",
              }}
            >
              Last 24 hours stats
            </div>
            <div
              className="agentmetrics"
              style={{ display: "flex", background: "#000", color: "#fff" }}
            >
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "0.5rem",
                  }}
                >
                  Alert Level &gt; 10
                </div>
                <div
                  className="alertlevel"
                  style={{
                    border: "1px solid black",
                    background: "#fff",
                    color: "#eb0404",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: gt10AlertsCount < 100 ? "6rem" : "4rem",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/logs/systems/gt10")}
                >
                  {gt10AlertsCount}
                </div>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "0.5rem",
                  }}
                >
                  Rootkit Alerts
                </div>
                <div
                  className="alertlevel"
                  style={{
                    border: "1px solid black",
                    background: "#fff",
                    color: "#eb0404",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: rootkitAlertsCount < 100 ? "6rem" : "4rem",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/logs/systems/rootcheck")}
                >
                  {rootkitAlertsCount}
                </div>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "0.5rem",
                  }}
                >
                  File Integrity Changes
                </div>
                <div
                  style={{
                    border: "1px solid black",
                    background: "#fff",
                    color: "#eb0404",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "230px",
                    height: "150px",
                    fontSize: fiimAlertsCount < 100 ? "6rem" : "4rem",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/logs/systems/syscheck")}
                >
                  {fiimAlertsCount}
                </div>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "0.5rem",
                  }}
                >
                  Attack Alerts
                </div>
                <div
                  className="alertlevel"
                  style={{
                    border: "1px solid black",
                    background: "#fff",
                    color: "#eb0404",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: attackAlertscount < 100 ? "6rem" : "4rem",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/logs/systems/attack")}
                >
                  {attackAlertscount}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ display: "flex" }}>
          <div>
            <AreaChart
              width={800}
              height={400}
              data={chartData}
              style={{ margin: "1rem" }}
            >
              <CartesianGrid stroke="#ccc" />
              <XAxis
                dataKey="time"
                allowDuplicatedCategory={false}
                style={{ fontSize: "0.5rem" }}
              />
              <YAxis />
              <Tooltip />
              <Legend />
              {timeSeriesData.map(({ key }) => {
                const colorr = `#${Math.floor(
                  Math.random() * 16777215
                ).toString(16)}`;
                return (
                  <Area
                    dot={false}
                    key={key}
                    type="monotone"
                    dataKey={key}
                    name={key}
                    stroke={colorr}
                    fill={colorr}
                  />
                );
              })}
            </AreaChart>

            {agentsInfo.length > 0 ? (
              <table className="agentstable">
                <thead>
                  <tr>
                    <th>Status</th>
                    <th>Name</th>
                    <th>IP ADDR</th>
                    <th>last seen</th>
                    <th>OS</th>
                  </tr>
                </thead>
                <tbody>
                  {agentsInfo.map((data) => {
                    return (
                      <tr key={data.id}>
                        <td
                          style={{
                            background: `${
                              data.status == "active" ? "#427042" : "#bf0505"
                            }`,
                            color: "white",
                            fontWeight: "500",
                          }}
                        >
                          {data.status}
                        </td>
                        <td>
                          <a
                            href={`/systemdashboard/${data.name}`}
                            style={{ textDecoration: "none" }}
                          >
                            {data.name}
                          </a>
                        </td>
                        <td className="cell-breakWord">{data.ip}</td>
                        <td>
                          {data.lastKeepAlive
                            ? new Date(data.lastKeepAlive).toLocaleString()
                            : "-"}
                        </td>
                        <td>
                          {data.os ? data.os.name : "-"}{" "}
                          {data.os ? data.os.version : ""}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                loading...
              </div>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              border: "1px solid grey",
              marginLeft: "1rem",
              width: "100vw",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "0.5rem",
              }}
            >
              Top Systems Generating Alerts
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingRight: "6rem",
              }}
            >
              <PieChart width={600} height={300}>
                <Legend
                  layout="vertical"
                  verticalAlign="top"
                  align="right"
                  wrapperStyle={{
                    padding: "0 2rem",
                    height: "80%",
                    overflow: "auto",
                  }}
                />
                <Pie
                  dataKey="doc_count"
                  nameKey="key"
                  isAnimationActive={false}
                  data={byAgentData}
                  innerRadius={75}
                  outerRadius={150}
                  stroke=""
                  fill="#8884d8"
                  // label={renderCustomizedLabel}
                  labelLine={false}
                >
                  {byGroupData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>

                <Tooltip />
              </PieChart>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "0.5rem",
              }}
            >
              Top Alert Groups
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <PieChart width={600} height={300}>
                <Legend
                  layout="vertical"
                  verticalAlign="top"
                  align="right"
                  wrapperStyle={{
                    padding: "0 2rem",
                    height: "80%",
                    overflow: "auto",
                  }}
                />
                <Pie
                  dataKey="doc_count"
                  nameKey="key"
                  data={byGroupData}
                  innerRadius={75}
                  outerRadius={150}
                  stroke=""
                  fill="#8884d8"
                  // label={renderCustomizedLabel}
                  labelLine={false}
                >
                  {byGroupData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>

                <Tooltip />
              </PieChart>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "0.5rem",
              }}
            >
              Source IP Addresses
            </div>
            <MapContainer center={position} zoom={1} style={{ height: "40vh" }}>
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <MultipleMarkers />
            </MapContainer>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div
      className="mycontainer"
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <UserNavbar />

      {role !== "1" ? (
        <>
          {systemsData.length > 0 ? (
            MasterDashboardDiv
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              No Agents Added
            </div>
          )}
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <ul
            style={{
              display: "flex",
              listStyle: "none",
              fontSize: "large",
            }}
          >
            <li
              style={{
                margin: "0 1rem",
                background: "#030B3A",
                padding: "0.5rem",
                color: "white",
                cursor: "pointer",
              }}
            >
              <a onClick={() => navigate("/addcustomer")}>Add Customer</a>
            </li>
            <li
              style={{
                margin: "0 1rem",
                background: "#030B3A",
                padding: "0.5rem",
                color: "white",
                cursor: "pointer",
              }}
            >
              <a onClick={() => navigate("/addlicense")}>Add License</a>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default MasterDashboard;
