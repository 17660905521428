import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useDashboard } from "../contexts/DashboardContext";
import "../App.css";

const SystemTable = () => {
  const { agentsList, setAgentsList } = useDashboard();
  const token = Cookies.get("token");
  const customerid = Cookies.get("customerid");

  useEffect(() => {
    axios
      .get(`/api/system/${customerid}`, {
        headers: {
          "x-auth-token": token,
        },
      })
      .then((res) => setAgentsList(res.data.systems));
  }, []);
  return (
    <>
      {agentsList.length > 0 ? (
        <table class="data_table" id="data_table">
          <thead>
            <tr>
              <th>ID</th>
              <th>SYSTEM</th>
              <th>IP ADDR</th>
              <th>GROUP</th>
              <th>LOCATION</th>
              <th>KEY</th>
            </tr>
          </thead>
          <tbody>
            {agentsList.map((data) => {
              return (
                <tr key={data.agentid}>
                  <td>{data.agentid}</td>
                  <td>{data.agentname}</td>
                  <td>{data.agentip}</td>
                  <td>{data.agentgroup}</td>
                  <td>{data.agentlocation}</td>
                  <td class="cell-breakWord">{data.agentkey}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          No agents added
        </div>
      )}
    </>
  );
};

export default SystemTable;
