import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import UserNavbar from "./UserNavbar";
import { useNavigate } from "react-router-dom";
import { useDashboard } from "../contexts/DashboardContext";
import "../App.css";

const AddCustomer = () => {
  const navigate = useNavigate();
  const token = Cookies.get("token");
  const role = Cookies.get("role");
  const { setAdmin } = useDashboard();
  const [credentials, setCredentials] = useState({
    name: "",
    expiry: "",
    username: "",
    password: "",
    email: "",
  });
  const [error, setError] = useState("");

  useEffect(() => {
    if (token && role) {
      if (role === "1") {
        setAdmin(true);
      }
    }
  }, []);

  useEffect(() => {
    if (!token) {
      navigate("/signin");
    } else {
      axios
        .get("/api/token/isLoggedIn", {
          headers: {
            "x-auth-token": token,
          },
        })
        .then((res) => console.log(res.data))
        .catch(() => navigate("/signin"));
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, expiry, username, password, email } = credentials;
    axios
      .post(
        "/api/Customer",
        {
          name,
          expiry,
          username,
          password,
          email,
        },
        {
          headers: {
            "x-auth-token": token,
          },
        }
      )
      .then((res) => {
        if (res.data.message !== "Successfully Created") {
          setError(res.data.message);
          setTimeout(() => {
            setError("");
          }, 1000);
        } else {
          setCredentials({
            name: "",
            expiry: "",
            username: "",
            password: "",
            email: "",
          });
          navigate("/dashboard");
        }
      })
      .catch((err) => {
        setError(err.response.data.message);
        setTimeout(() => {
          setError("");
        }, 1000);
      });
  };
  const handleChange = (e) => {
    e.preventDefault();
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  return (
    <>
      <UserNavbar />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "75vh",
        }}
      >
        <div className="form-2-parent">
          <h2 style={{ textAlign: "center" }}>Add Customer</h2>
          <form className="form-2" onSubmit={handleSubmit}>
            <label> Name</label>
            <br />
            <input
              type="text"
              placeholder="Type customer name here | Should start with a letter, Ex: John doe"
              name="name"
              id="name"
              pattern="^[a-zA-Z]+.*$"
              value={credentials.name}
              onChange={handleChange}
              required
            ></input>
            <br />
            <label>Expiry Date</label>
            <br />
            <input
              type="text"
              placeholder="Type expiry date here"
              name="expiry"
              id="expiry"
              onFocus={(e) => {
                e.currentTarget.type = "date";
                e.currentTarget.min = new Date().toISOString().split("T")[0];
                e.currentTarget.max = "2050-01-01";
              }}
              value={credentials.expiry}
              onChange={handleChange}
              required
            ></input>
            <br />
            <label> Username</label>
            <br />
            <input
              type="text"
              placeholder="Type customer username here | Should start with a letter, Ex: johndoe"
              name="username"
              id="username"
              pattern="^[a-z|A-Z|]+[a-z|A-Z|0-9]*"
              value={credentials.username}
              onChange={handleChange}
              required
            ></input>
            <br />
            <label> Password</label>
            <br />
            <input
              type="password"
              placeholder="Type customer password here"
              name="password"
              id="password"
              value={credentials.password}
              onChange={handleChange}
              required
            ></input>
            <br />
            <label> Email</label>
            <br />
            <input
              type="email"
              placeholder="Type customer email here"
              name="email"
              id="email"
              value={credentials.email}
              onChange={handleChange}
              required
            ></input>
            {error}
            <br />
            <br />
            <a onClick={() => navigate("/dashboard")}>
              <button
                type="button"
                className="btn"
                style={{
                  float: "left",
                  background: "grey",
                  border: "none",
                  padding: "0.5rem",
                  cursor: "pointer",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                Cancel
              </button>
            </a>
            <button
              type="submit"
              style={{
                float: "right",
                padding: "0.5rem",
                cursor: "pointer",
                background: "#030B3A",
                border: "none",
                color: "white",
                fontWeight: "bold",
              }}
            >
              Add Customer
            </button>
            <br />
          </form>
        </div>
      </div>
    </>
  );
};

export default AddCustomer;
