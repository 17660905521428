import React, { useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useDashboard } from "../contexts/DashboardContext";
import "../App.css";

const LicenseInformation = () => {
  const { licenseInfo, setLicenseInfo } = useDashboard();
  const token = Cookies.get("token");
  const customerid = Cookies.get("customerid");

  useEffect(() => {
    axios
      .get(`/api/customer/${customerid}`, {
        headers: {
          "x-auth-token": token,
        },
      })
      .then((res) => setLicenseInfo(res.data.customers));
  }, []);
  return (
    <>
      <div class="boxes">
        <div
          class="box1"
          style={{
            background: "white",
            padding: "1rem",
            border: "1.5px solid lightgray",
          }}
        >
          <div class="contenttt">
            <h3>
              <i class="fa-solid fa-key"></i>&nbsp; Total Licenses
            </h3>
            <br></br>

            {licenseInfo.length > 0 ? <p>{licenseInfo[0].count}</p> : "0"}
          </div>
        </div>
        <div
          class="box2"
          style={{
            background: "white",
            padding: "1rem",
            border: "1.5px solid lightgray",
          }}
        >
          <div class="contenttt">
            <h3>
              <i class="fa-solid fa-key"></i>&nbsp; Available Licences
            </h3>
            <br></br>

            {licenseInfo.length > 0 ? <p>{licenseInfo[0].available}</p> : "0"}
          </div>
        </div>
      </div>
    </>
  );
};

export default LicenseInformation;
