import React, { useState, useEffect } from "react";
import "../App.css";

const SideBox = () => {
  return (
    <>
      <div class="side_box">
        <ul>
          <li>
            <a href="#">
              <i class="fa-brands fa-windows"></i> Systems
            </a>
          </li>
          <li>
            <a href="#">
              <i class="fa-solid fa-bug"></i> Backlog
            </a>
          </li>
          <li>
            <a href="#">
              <i class="fa-solid fa-compass"></i> Roadmap
            </a>
          </li>
          <li>
            <a href="#">
              <i class="fa-solid fa-laptop-file"></i> Reports
            </a>
          </li>
          <li>
            <a href="#">
              <i class="fa-solid fa-truck"></i> Releases
            </a>
          </li>
          <br></br>
          <hr></hr>
          <br></br>
          <li>
            <a href="#">
              <i class="fa-solid fa-people-group"></i> Teams
            </a>
          </li>
          <li>
            <a href="#">
              <i class="fa-solid fa-gear"></i> Project settings
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default SideBox;
