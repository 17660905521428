import React, { useEffect, useState } from "react";
import UserNavbar from "./UserNavbar";
import Cookies from "js-cookie";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import {
  PieChart,
  Pie,
  Legend,
  Tooltip,
  Cell,
  CartesianGrid,
  XAxis,
  YAxis,
  AreaChart,
  Area,
} from "recharts";
import { MapContainer, TileLayer, Circle } from "react-leaflet";
import "../App.css";
import "leaflet/dist/leaflet.css";

const SystemDashboard = () => {
  const [systemsData, setSystemsData] = useState([]);
  const [node, setNode] = useState("");
  const [timeFrame, setTimeFrame] = useState("24h");
  const [selectedTab, setSelectedTab] = useState(1);
  const [isWindows, setIsWindows] = useState(false);
  const [statusInfo, setStatusInfo] = useState("");
  const [allAlertsCount, setAllAlertsCount] = useState(0);
  const [gt10AlertsCount, setGt10AlertsCount] = useState(0);
  const [rootkitAlertsCount, setRootkitAlertsCount] = useState(0);
  const [fiimAlertsCount, setFiimAlertsCount] = useState(0);
  const [attackAlertscount, setAttackAlertsCount] = useState(0);
  const [byLocationData, setByLocationData] = useState([]);
  const [byGroupData, setByGroupData] = useState([]);
  const [timeSeriesData, setTimeSeriesData] = useState([]);
  const [geoLocationData, setGeoLocationData] = useState([]);
  const [notScaAlertsData, setNotScaAlertsData] = useState([]);
  const [ipList, setIpList] = useState([]);
  const token = Cookies.get("token");
  const role = Cookies.get("role");
  const customerid = Cookies.get("customerid");
  const navigate = useNavigate();
  const params = useParams();

  let timeFrameData =
    selectedTab === 1
      ? ["1h", "12h", "24h", "7d"]
      : ["15m", "30m", "1h", "6h", "12h", "24h"];

  useEffect(() => {
    if (!token) {
      navigate("/signin");
    } else {
      axios
        .get("/api/token/isLoggedIn", {
          headers: {
            "x-auth-token": token,
          },
        })
        .then((res) => console.log(res.data))
        .catch(() => navigate("/signin"));
    }
  }, []);

  useEffect(() => {
    axios
      .get(`/api/system/${customerid}`, {
        headers: {
          "x-auth-token": token,
        },
      })
      .then((res) => setSystemsData(res.data.systems))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (node?.length > 0) {
      axios
        .get(`/api/system/node/${node}/${customerid}/iswindows`, {
          headers: {
            "x-auth-token": token,
          },
        })
        .then((res) => setIsWindows(res.data))
        .catch((err) => console.log(err));
    }
  }, [node]);

  useEffect(() => {
    setNode(params.agent);
  }, []);

  useEffect(() => {
    if (!isWindows) {
      setSelectedTab(1);
    }
  }, [isWindows]);

  useEffect(() => {
    if (selectedTab === 1) {
      setTimeFrame("24h");
    } else {
      setTimeFrame("1h");
    }
  }, [selectedTab]);

  useEffect(() => {
    if (role === "3" && node?.length > 0) {
      axios
        .get(`/api/system/agentstatusinfo/${node}/${customerid}`, {
          headers: {
            "x-auth-token": token,
          },
        })
        .then((res) => setStatusInfo(res.data));
    }
  }, [node, timeFrame]);

  useEffect(() => {
    if (role === "3" && node?.length > 0) {
      axios
        .get(`/api/system/node/type/${node}/${customerid}/all`, {
          params: {
            hours: timeFrame,
          },
          headers: {
            "x-auth-token": token,
          },
        })
        .then((res) => setAllAlertsCount(res.data));
    }
  }, [node, timeFrame]);

  useEffect(() => {
    if (role === "3" && node?.length > 0) {
      axios
        .get(`/api/system/node/type/${node}/${customerid}/gt10`, {
          params: {
            hours: timeFrame,
          },
          headers: {
            "x-auth-token": token,
          },
        })
        .then((res) => setGt10AlertsCount(res.data));
    }
  }, [node, timeFrame]);

  useEffect(() => {
    if (role === "3" && node?.length > 0) {
      axios
        .get(`/api/system/node/type/${node}/${customerid}/rootcheck`, {
          params: {
            hours: timeFrame,
          },
          headers: {
            "x-auth-token": token,
          },
        })
        .then((res) => setRootkitAlertsCount(res.data));
    }
  }, [node, timeFrame]);

  useEffect(() => {
    if (role === "3" && node?.length > 0) {
      axios
        .get(`/api/system/node/type/${node}/${customerid}/syscheck`, {
          params: {
            hours: timeFrame,
          },
          headers: {
            "x-auth-token": token,
          },
        })
        .then((res) => setFiimAlertsCount(res.data));
    }
  }, [node, timeFrame]);

  useEffect(() => {
    if (role === "3" && node?.length > 0) {
      axios
        .get(`/api/system/node/type/${node}/${customerid}/attack`, {
          params: {
            hours: timeFrame,
          },
          headers: {
            "x-auth-token": token,
          },
        })
        .then((res) => setAttackAlertsCount(res.data));
    }
  }, [node, timeFrame]);

  useEffect(() => {
    if (role === "3" && node?.length > 0) {
      axios
        .get(`/api/system/node/group/${node}/${customerid}/bygroup`, {
          params: {
            hours: timeFrame,
          },
          headers: {
            "x-auth-token": token,
          },
        })
        .then((res) => setByGroupData(res.data));
    }
  }, [node, timeFrame]);

  useEffect(() => {
    if (role === "3" && node?.length > 0) {
      axios
        .get(`/api/system/node/group/${node}/${customerid}/bylocation`, {
          params: {
            hours: timeFrame,
          },
          headers: {
            "x-auth-token": token,
          },
        })
        .then((res) => setByLocationData(res.data));
    }
  }, [node, timeFrame]);

  useEffect(() => {
    if (role === "3" && node?.length > 0) {
      axios
        .get(`/api/system/node/timeseries/${node}/${customerid}`, {
          params: {
            hours: timeFrame,
          },
          headers: {
            "x-auth-token": token,
          },
        })
        .then((res) => setTimeSeriesData(res.data));
    }
  }, [node, timeFrame]);

  useEffect(() => {
    if (role === "3" && node?.length > 0) {
      axios
        .get(`/api/system/node/notscatable/${node}/${customerid}`, {
          params: {
            hours: timeFrame,
          },
          headers: {
            "x-auth-token": token,
          },
        })
        .then((res) => setNotScaAlertsData(res.data));
    }
  }, [node, timeFrame]);

  useEffect(() => {
    if (role === "3" && node?.length > 0) {
      axios
        .get(`/api/system/node/map/${node}/${customerid}`, {
          params: {
            hours: timeFrame,
          },
          headers: {
            "x-auth-token": token,
          },
        })
        .then((res) => setGeoLocationData(res.data));
    }
  }, [node, timeFrame]);

  useEffect(() => {
    if (role === "3" && node?.length > 0) {
      axios
        .get(`/api/system/node/ip/${node}/${customerid}`, {
          params: {
            hours: timeFrame,
          },
          headers: {
            "x-auth-token": token,
          },
        })
        .then((res) => setIpList(res.data));
    }
  }, [node, timeFrame]);

  const handleChange = (event) => {
    setNode(event.target.value);
  };
  const handleTimeFrameChange = (event) => {
    setTimeFrame(event.target.value);
  };

  const COLORS = [
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#FF8042",
    "#FF6633",
    "#FFB399",
    "#FF33FF",
    "#FFFF99",
    "#00B3E6",
    "#E6B333",
    "#3366E6",
    "#999966",
    "#99FF99",
    "#B34D4D",
    "#80B300",
    "#809900",
    "#E6B3B3",
    "#6680B3",
    "#66991A",
    "#FF99E6",
    "#CCFF1A",
    "#FF1A66",
    "#E6331A",
    "#33FFCC",
    "#66994D",
    "#B366CC",
    "#4D8000",
    "#B33300",
    "#CC80CC",
    "#66664D",
    "#991AFF",
    "#E666FF",
    "#4DB3FF",
    "#1AB399",
    "#E666B3",
    "#33991A",
    "#CC9999",
    "#B3B31A",
    "#00E680",
    "#4D8066",
    "#809980",
    "#E6FF80",
    "#1AFF33",
    "#999933",
    "#FF3380",
    "#CCCC00",
    "#66E64D",
    "#4D80CC",
    "#9900B3",
    "#E64D66",
    "#4DB380",
    "#FF4D4D",
    "#99E6E6",
    "#6666FF",
  ];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
        fontSize="0.90rem"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const position = [8.7832, 34.5085];

  function MultipleMarkers() {
    return geoLocationData.map((coordinata, index) => {
      return (
        <Circle
          center={coordinata.coordinates}
          fillColor="green"
          radius={coordinata.doc_count * 50}
          weight={1}
          color="green"
        />
      );
    });
  }

  const chartData = timeSeriesData.flatMap(({ key, timestamp }) =>
    timestamp.map(({ key: time, doc_count }) => ({
      time: new Date(time).toLocaleString(),
      [key]: doc_count,
    }))
  );

  const showTabs = () => {
    if (isWindows)
      return (
        <div
          style={{
            display: "flex",
            margin: "auto",
            background: "#9d9c9c",
            color: "white",
            width: "max-content",
            marginBottom: "1rem",
            cursor: "pointer",
          }}
        >
          <div
            style={{
              background: selectedTab === 1 ? "#05123A" : "inherit",
              padding: "1rem",
            }}
            onClick={() => setSelectedTab(1)}
          >
            Alerts Monitoring
          </div>
          <div
            style={{
              background: selectedTab === 2 ? "#05123A" : "inherit",
              padding: "1rem",
            }}
            onClick={() => setSelectedTab(2)}
          >
            Health Monitoring
          </div>
        </div>
      );
  };

  const showAlertsMonitoringPanels = () => {
    return (
      <>
        <div
          className="systemdashboard"
          style={{ display: "flex", justifyContent: "space-evenly" }}
        >
          <div
            className="agentmetrics"
            style={{
              display: "flex",
              border: "1px solid black",
              width: "40vw",
              justifyContent: "space-around",
            }}
          >
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "0.5rem",
                }}
              >
                Status
              </div>
              <div>
                <div
                  className="alertlevel"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "1.5rem",
                  }}
                >
                  <div>{statusInfo[0]}</div>
                  <br />
                  <div>{statusInfo[1]}</div>
                </div>
              </div>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "0.5rem",
                }}
              >
                Total Alerts
              </div>
              <div
                className="alertlevel"
                style={{
                  background: "#fff",
                  color: "#eb0404",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "6rem",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
                onClick={() => navigate(`/logs/node/${node}/all/${timeFrame}`)}
              >
                {allAlertsCount}
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "max-content",
              margin: "1rem",
              padding: "1rem 0",
            }}
          >
            <div style={{ padding: "0 1rem", display: "flex" }}>
              <div
                className="alertlevelgt10"
                style={{
                  border: "1px solid black",
                  background: "#05123A",
                  color: "white",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Alert Level &gt; 10
                </div>
                <div
                  className="alertlevel"
                  style={{
                    border: "1px solid black",
                    background: "#fff",
                    color: "#eb0404",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: gt10AlertsCount < 100 ? "6rem" : "4rem",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    navigate(`/logs/node/${node}/gt10/${timeFrame}`)
                  }
                >
                  {gt10AlertsCount}
                </div>
              </div>
              <div
                className="rootkitalerts"
                style={{
                  border: "1px solid black",
                  background: "#05123A",
                  color: "white",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Rootkit Alerts
                </div>
                <div
                  className="alertlevel"
                  style={{
                    border: "1px solid black",
                    background: "#fff",
                    color: "#eb0404",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: rootkitAlertsCount < 100 ? "6rem" : "4rem",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    navigate(`/logs/node/${node}/rootcheck/${timeFrame}`)
                  }
                >
                  {rootkitAlertsCount}
                </div>
              </div>
              <div
                className="fileintegritychanges"
                style={{
                  border: "1px solid black",
                  background: "#05123A",
                  color: "white",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  File Integrity Changes
                </div>
                <div
                  className="alertlevel"
                  style={{
                    border: "1px solid black",
                    background: "#fff",
                    color: "#eb0404",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "230px",
                    height: "150px",
                    fontSize: fiimAlertsCount < 100 ? "6rem" : "4rem",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    navigate(`/logs/node/${node}/syscheck/${timeFrame}`)
                  }
                >
                  {fiimAlertsCount}
                </div>
              </div>
              <div
                className="attackalerts"
                style={{
                  border: "1px solid black",
                  background: "#05123A",
                  color: "white",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Attack Alerts
                </div>
                <div
                  className="alertlevel"
                  style={{
                    border: "1px solid black",
                    background: "#fff",
                    color: "#eb0404",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: attackAlertscount < 100 ? "6rem" : "4rem",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    navigate(`/logs/node/${node}/attack/${timeFrame}`)
                  }
                >
                  {attackAlertscount}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="systemdashboard"
          style={{ display: "flex", justifyContent: "space-evenly" }}
        >
          <div>
            <AreaChart width={800} height={400} data={chartData}>
              <CartesianGrid stroke="#ccc" />
              <XAxis
                dataKey="time"
                allowDuplicatedCategory={false}
                style={{ fontSize: "0.5rem" }}
              />
              <YAxis />
              <Tooltip />
              <Legend />
              {timeSeriesData.map(({ key }) => {
                const colorr = `#${Math.floor(
                  Math.random() * 16777215
                ).toString(16)}`;
                return (
                  <Area
                    dot={false}
                    key={key}
                    type="monotone"
                    dataKey={key}
                    name={key}
                    stroke={colorr}
                    fill={colorr}
                  />
                );
              })}
            </AreaChart>

            <div style={{ marginLeft: "10rem" }}>
              <thead>
                <tr style={{ background: "black", color: "white" }}>
                  <th>RULE</th>
                  <th>DESCRIPTION</th>
                  <th>LEVEL</th>
                  <th>GROUPS</th>
                </tr>
              </thead>
              <tbody>
                {notScaAlertsData.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item._source.rule.id}</td>
                      <td>{item._source.rule.description}</td>
                      <td>{item._source.rule.level}</td>
                      <td>
                        {item._source.rule.groups.map((item) => item + " ")}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              border: "1px solid grey",
              marginLeft: "1rem",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "0.5rem",
              }}
            >
              Top Alert Groups
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <PieChart width={600} height={300}>
                <Legend
                  layout="vertical"
                  verticalAlign="top"
                  align="right"
                  wrapperStyle={{
                    padding: "0 2rem",
                    height: "80%",
                    overflow: "auto",
                  }}
                />
                <Pie
                  dataKey="doc_count"
                  nameKey="key"
                  isAnimationActive={false}
                  data={byGroupData}
                  innerRadius={75}
                  outerRadius={150}
                  stroke=""
                  fill="#8884d8"
                  // label={renderCustomizedLabel}
                  labelLine={false}
                >
                  {byGroupData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>

                <Tooltip />
              </PieChart>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "0.5rem",
              }}
            >
              Top Alert Groups by log file
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <PieChart width={800} height={300}>
                <Legend
                  layout="vertical"
                  verticalAlign="top"
                  align="right"
                  wrapperStyle={{
                    padding: "0 2rem",
                    height: "80%",
                    overflow: "auto",
                  }}
                />
                <Pie
                  dataKey="doc_count"
                  nameKey="key"
                  isAnimationActive={false}
                  data={byLocationData}
                  innerRadius={75}
                  outerRadius={150}
                  stroke=""
                  fill="#8884d8"
                  // label={renderCustomizedLabel}
                  labelLine={false}
                >
                  {byLocationData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>

                <Tooltip />
              </PieChart>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "0.5rem",
              }}
            >
              Source IP Addresses
            </div>
            <MapContainer center={position} zoom={1} style={{ height: "40vh" }}>
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <MultipleMarkers />
            </MapContainer>
            <div>
              <ul style={{ listStyle: "none", border: "1px solid black" }}>
                {ipList.map((ip) => (
                  <li style={{ border: "1px solid black" }}>{ip}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  };

  const showHealthMonitoringPanels = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          Work In Progress!
        </div>
        {/* <div style={{ display: "flex", marginBottom: "5rem" }}>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "1rem",
                fontSize: "1.1rem",
              }}
            >
              Memory Usage
            </div>
            <iframe
              src={`${url}:3000/d-solo/Mir2RJHWz/ossec-summary?orgId=1&var-node=${node}&from=now-${timeFrame}&to=now&refresh=30s&theme=light&panelId=85`}
              style={{
                width: "40vw",
                height: "30vh",
                margin: "0 4rem 0 0",
              }}
              frameborder="0"
            ></iframe>
          </div>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "1rem",
                fontSize: "1.1rem",
              }}
            >
              CPU Usage
            </div>
            <iframe
              src={`${url}:3000/d-solo/Mir2RJHWz/ossec-summary?orgId=1&var-node=${node}&from=now-${timeFrame}&to=now&refresh=30s&theme=light&panelId=86`}
              style={{
                width: "40vw",
                height: "30vh",
                margin: "0 4rem 0 0",
              }}
              frameborder="0"
            ></iframe>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "1rem",
                fontSize: "1.1rem",
              }}
            >
              Network Usage
            </div>
            <iframe
              src={`${url}:3000/d-solo/Mir2RJHWz/ossec-summary?orgId=1&var-node=${node}&from=now-${timeFrame}&to=now&refresh=30s&theme=light&panelId=87`}
              style={{
                width: "40vw",
                height: "30vh",
                margin: "0 4rem 0 0",
              }}
              frameborder="0"
            ></iframe>
          </div>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "1rem",
                fontSize: "1.1rem",
              }}
            >
              Disk Available
            </div>
            <iframe
              src={`${url}:3000/d-solo/Mir2RJHWz/ossec-summary?orgId=1&var-node=${node}&from=now-${timeFrame}&to=now&refresh=30s&theme=light&panelId=88`}
              style={{
                width: "40vw",
                height: "30vh",
                margin: "0 4rem 0 0",
              }}
              frameborder="0"
            ></iframe>
          </div>
        </div> */}
      </div>
    );
  };

  return (
    <div
      className="mycontainer"
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <UserNavbar />

      {systemsData.length > 0 ? (
        <>
          <div>
            <div
              style={{
                padding: "1rem",
                fontSize: "2rem",
                fontWeight: "bold",
              }}
            >
              <span style={{ padding: "1rem" }}>System</span>
              <select
                value={node}
                onChange={handleChange}
                style={{
                  marginBottom: "1rem",
                  fontSize: "1rem",
                  padding: "1rem",
                }}
              >
                <option value="">Select System</option>

                {systemsData.map((value, i) => {
                  return (
                    <option key={value.agentid} value={value.agentname}>
                      {value.agentname}
                    </option>
                  );
                })}
              </select>

              <span style={{ padding: "1rem" }}>TimeFrame</span>
              <select
                value={timeFrame}
                onChange={handleTimeFrameChange}
                style={{
                  marginBottom: "1rem",
                  fontSize: "1rem",
                  padding: "1rem",
                }}
              >
                {timeFrameData.map((value, i) => {
                  return (
                    <option key={i} value={value}>
                      Last {value}
                    </option>
                  );
                })}
              </select>
            </div>

            {node ? (
              <>
                {showTabs()}
                {selectedTab === 1
                  ? showAlertsMonitoringPanels()
                  : showHealthMonitoringPanels()}
              </>
            ) : (
              <h3
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "20vh",
                }}
              >
                No agent selected!
              </h3>
            )}
          </div>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          No Agents Added
        </div>
      )}
    </div>
  );
};

export default SystemDashboard;
