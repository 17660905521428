import React from "react";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import logoheader from "../Images/logo-white.webp";
import logofooter from "../Images/logo-blue.webp";
import firstslide from "../Images/master-dashboard-new.png";
import secondslide from "../Images/system-dashboard-new-full.png";
import "../App.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const LandingPage = () => {
  return (
    <>
      <div className="container">
        <img src={logoheader} className="logo"></img>
        <ul>
          <li>
            <a href="#">Features</a>&nbsp;
            <i className="fa-solid fa-caret-down"></i>
            <div className="dropdown-menu">
              <ul>
                <li>
                  <a href="#">Feat 1</a>
                </li>
                <li>
                  <a href="#">Feat 2</a>
                </li>
                <li>
                  <a href="#">Feat 3</a>
                </li>
                <li>
                  <a href="#">Feat 4</a>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <a href="#">About Us</a>
          </li>
          <li>
            <a href="#">Pricing</a>
          </li>
          <li>
            <a href="#">Blog</a>
          </li>
        </ul>

        <div className="buttons">
          <a style={{ textDecoration: "none" }} href="/signin">
            <button className="btn1">Sign In</button>
          </a>
          <button className="btn2">Sign Up</button>
        </div>
      </div>

      <div className="mainbody">
        <div className="content">
          <h1>
            SecurWeave <br></br>
            Host Intrusion Detection
            <br></br>
            System
          </h1>
          <br></br>
          <br></br>
          <p>
            - Intrusion Detection<br></br>- File Integrity Monitoring<br></br>-
            Event Management<br></br>- Root Kit Detection & Mitigation
          </p>
          <br></br>
          <button className="btn3">Learn More</button>
        </div>
        <div className="myswiper">
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
            slidesPerView={1}
            navigation
            autoplay
          >
            <SwiperSlide>
              <img src={firstslide}></img>
            </SwiperSlide>
            <SwiperSlide>
              <img src={secondslide}></img>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>

      <footer>
        <div className="container_flex">
          <img src={logofooter}></img>
          <div className="left_foot">
            <div className="link-col">
              <h3>Product</h3>
              <br></br>
              <a href="#">Features</a> <br></br>
              <br></br>
              <a href="#">Pricing</a>
            </div>
            <div className="link-col">
              <h3>Resources</h3>
              <br></br>
              <a href="https://www.securweave.com/blogs" target="blank">
                Blog
              </a>
              <br></br>
              <br></br>
              <a href="#">User guides</a>
              <br></br>
              <br></br>
              <a href="#">Webinars</a>
            </div>
          </div>
          <div className="right_foot">
            <div className="link-col">
              <h3>Company</h3>
              <br></br>
              <a href="https://www.securweave.com/about-us" target="blank">
                About
              </a>
              <br></br>
              <br></br>
              <a href="https://www.securweave.com/careers" target="blank">
                Join Us
              </a>
            </div>
            <div className="link-col">
              <h3>Support</h3>
              <br></br>
              <a href="#">Help Centre</a>
              <br></br>
              <br></br>
              <a href="#">Chat support</a>
            </div>
          </div>
        </div>
      </footer>
      <div className="subfooter">
        <div className="copyright">
          <i className="fa-solid fa-copyright"></i>&nbsp;
          <p>2023 by SecurWeave</p>
          {/* <ul>
            <li>Privacy</li>
            <li>Terms</li>
            <li>Sitemap</li>
          </ul> */}
        </div>
        <div className="social-handles">
          <a
            target="_blank"
            href="https://www.linkedin.com/company/securweave-research-labs-pvt-ltd/"
            alt="Linkedin"
          >
            <i className="fa-brands fa-linkedin"></i>
          </a>
          <a
            target="_blank"
            href="https://www.youtube.com/channel/UCMgFldw6VY1fW0Qz_2hvQPg/featured"
            alt=""
          >
            <div className="yt">
              <i className="fa-brands fa-youtube"></i>
            </div>
          </a>
          <a
            target="_blank"
            href="https://mobile.twitter.com/securweave"
            alt="Twitter"
          >
            <i className="fa-brands fa-square-twitter"></i>
          </a>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
