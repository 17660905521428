import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import UserNavbar from "./UserNavbar";
import "../App.css";

const SystemLogs = () => {
  const [timeFrame, setTimeFrame] = useState("");
  const [node, setNode] = useState("");
  const [group, setGroup] = useState("");
  const [postsPerPage, setPostsPerPage] = useState(5);
  const [allAlertsData, setAllAlertsData] = useState([]);
  const [allAlertsCurrentPage, setAllAlertsCurrentPage] = useState(1);
  const [dropdownIndex, setDropdownIndex] = useState(-1);
  const [tab, setTab] = useState("table");
  const token = Cookies.get("token");
  const customerid = Cookies.get("customerid");
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (!token) {
      navigate("/signin");
    } else {
      axios
        .get("/api/token/isLoggedIn", {
          headers: {
            "x-auth-token": token,
          },
        })
        .then((res) => console.log(res.data))
        .catch(() => navigate("/signin"));
    }
  }, []);

  useEffect(() => {
    setNode(params.node);
  }, []);

  useEffect(() => {
    setGroup(params.group);
  }, []);

  useEffect(() => {
    setTimeFrame(params.timeframe);
  }, []);

  useEffect(() => {
    axios
      .get(`/api/system/node/table/type/${node}/${customerid}/${group}`, {
        params: {
          hours: timeFrame,
        },
        headers: {
          "x-auth-token": token,
        },
      })
      .then((res) => {
        setAllAlertsData(res.data);
      });
  }, [node, group]);

  const showAlertType = () => {
    if (group === "syscheck") {
      return "File Integrity Changes";
    } else if (group === "rootcheck") {
      return "Rootkit Alerts";
    } else if (group === "attack") {
      return "Attack Alerts";
    } else if (group === "gt10") {
      return "Alerts Level > 10";
    } else if (group === "all") {
      return "All Alerts";
    } else {
      return group;
    }
  };

  const showTab = (item) => {
    if (tab === "table") {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            paddingLeft: "6rem",
          }}
        >
          <table>
            <tbody>
              {item._source.agent &&
                Object.entries(item._source.agent).map((entry) => {
                  let key = entry[0];
                  let value = entry[1];
                  return (
                    <tr>
                      <td style={{ border: "none" }}>agent.{key}</td>
                      <td style={{ border: "none" }}>{value}</td>
                    </tr>
                  );
                })}
              {item._source.GeoLocation &&
                item._source.GeoLocation.location &&
                Object.entries(item._source.GeoLocation.location).map(
                  (entry) => {
                    let key = entry[0];
                    let value = entry[1];
                    return (
                      <tr>
                        <td style={{ border: "none" }}>GeoLocation.{key}</td>
                        <td style={{ border: "none" }}>{value}</td>
                      </tr>
                    );
                  }
                )}
              {/* {item._source.data && item._source.data.win
                ? Object.entries(item._source.data.win.eventdata).map(
                    (entry) => {
                      let key = entry[0];
                      let value = entry[1];
                      return (
                        <tr>
                          <td style={{ border: "none" }}>
                            win.eventdata.{key}
                          </td>
                          <td style={{ border: "none" }}>{value}</td>
                        </tr>
                      );
                    }
                  )
                : Object.entries(item._source.data).map((entry) => {
                    let key = entry[0];
                    let value = entry[1];
                    return (
                      <tr>
                        <td style={{ border: "none" }}>data.{key}</td>
                        <td style={{ border: "none" }}>{value}</td>
                      </tr>
                    );
                  })} */}
              {/* {item._source.data?.win &&
                Object.entries(item._source.data.win?.system).map((entry) => {
                  let key = entry[0];
                  let value = entry[1];
                  return (
                    <tr>
                      <td style={{ border: "none" }}>win.system.{key}</td>
                      <td style={{ border: "none" }}>{value}</td>
                    </tr>
                  );
                })} */}
              {item._source.decoder &&
                Object.entries(item._source.decoder).map((entry) => {
                  let key = entry[0];
                  let value = entry[1];
                  return (
                    <tr>
                      <td style={{ border: "none" }}>decoder.{key}</td>
                      <td style={{ border: "none" }}>{value}</td>
                    </tr>
                  );
                })}
              {item._source.input &&
                Object.entries(item._source.input).map((entry) => {
                  let key = entry[0];
                  let value = entry[1];
                  return (
                    <tr>
                      <td style={{ border: "none" }}>input.{key}</td>
                      <td style={{ border: "none" }}>{value}</td>
                    </tr>
                  );
                })}
              {item._source.manager &&
                Object.entries(item._source.manager).map((entry) => {
                  let key = entry[0];
                  let value = entry[1];
                  return (
                    <tr>
                      <td style={{ border: "none" }}>manager.{key}</td>
                      <td style={{ border: "none" }}>{value}</td>
                    </tr>
                  );
                })}
              {item._source.rule && item._source.rule.mitre
                ? Object.entries(item._source.rule.mitre).map((entry) => {
                    let key = entry[0];
                    let value = entry[1];
                    return (
                      <tr>
                        <td style={{ border: "none" }}>rule.mitre.{key}</td>
                        {key === "technique" || key === "tactic" ? (
                          <td style={{ border: "none" }}>
                            {value.map((item) => item + " ")}
                          </td>
                        ) : (
                          <td style={{ border: "none" }}>{value}</td>
                        )}
                      </tr>
                    );
                  })
                : Object.entries(item._source.rule).map((entry) => {
                    let key = entry[0];
                    let value = entry[1];
                    return (
                      <tr>
                        <td style={{ border: "none" }}>rule.{key}</td>
                        {key === "groups" ? (
                          <td style={{ border: "none" }}>
                            {value.map((item) => item + " ")}
                          </td>
                        ) : (
                          <td style={{ border: "none" }}>{value}</td>
                        )}
                      </tr>
                    );
                  })}
            </tbody>
          </table>
        </div>
      );
    } else if (tab === "json") {
      return (
        <div style={{ textAlign: "left", padding: "1.5rem" }}>
          <pre>
            <code>{JSON.stringify(item._source, undefined, 2)}</code>
          </pre>
        </div>
      );
    } else if (tab === "rule") {
      return <div>RULE</div>;
    }
  };

  const showAllAlertsData = () => {
    const indexOfLastPage = allAlertsCurrentPage * postsPerPage;
    const indexOfFirstPage = indexOfLastPage - postsPerPage;
    const currentPosts = allAlertsData.slice(indexOfFirstPage, indexOfLastPage);

    try {
      return currentPosts.map((item, index) => {
        return (
          <>
            <tr
              key={index}
              onClick={() =>
                dropdownIndex === index
                  ? setDropdownIndex(-1)
                  : setDropdownIndex(index)
              }
              style={{
                background: dropdownIndex === index ? "aliceblue" : "inherit",
                cursor: "pointer",
              }}
            >
              <td style={{ padding: "1rem" }}>
                {dropdownIndex === index ? (
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                    class="euiIcon euiIcon--small"
                    focusable="false"
                    role="img"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="non-zero"
                      d="M13.069 5.157L8.384 9.768a.546.546 0 01-.768 0L2.93 5.158a.552.552 0 00-.771 0 .53.53 0 000 .759l4.684 4.61c.641.631 1.672.63 2.312 0l4.684-4.61a.53.53 0 000-.76.552.552 0 00-.771 0z"
                    ></path>
                  </svg>
                ) : (
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                    class="euiIcon euiIcon--small"
                    focusable="false"
                    role="img"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="nonzero"
                      d="M5.157 13.069l4.611-4.685a.546.546 0 000-.768L5.158 2.93a.552.552 0 010-.771.53.53 0 01.759 0l4.61 4.684c.631.641.63 1.672 0 2.312l-4.61 4.684a.53.53 0 01-.76 0 .552.552 0 010-.771z"
                    ></path>
                  </svg>
                )}
              </td>
              <td>
                {new Date(item._source.timestamp)
                  .toLocaleString()
                  .replace(",", "")}
              </td>
              {/* <td style={{ color: "#0078a3" }}>
                {item._source.agent ? item._source.agent.id : "-"}
              </td>
              <td>{item._source.agent ? item._source.agent.name : "-"}</td> */}
              <td>
                {item._source.rule.mitre && item._source.rule.groups
                  ? item._source.rule.groups.map((item) => item + " ")
                  : "-"}
              </td>
              <td>
                {item._source.rule.mitre && item._source.full_log
                  ? item._source.full_log
                  : "-"}
              </td>
              <td>{item._source.rule ? item._source.rule.description : "-"}</td>
              <td>{item._source.rule ? item._source.rule.level : "-"}</td>

              <td style={{ color: "#0078a3" }}>{item._source.rule.id}</td>
            </tr>
            {dropdownIndex === index ? (
              <tr>
                <td colSpan="100%">
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        paddingLeft: "5rem",
                        borderBottom: "2px solid black",
                        fontSize: "1rem",
                      }}
                    >
                      <div
                        style={{
                          padding: "1rem",
                          fontWeight: "bold",
                          borderBottom:
                            tab === "table" ? "2px solid #0894D5" : "none",
                          color: tab === "table" ? "#0894D5" : "inherit",
                        }}
                        onClick={() => setTab("table")}
                      >
                        Table
                      </div>
                      <div
                        style={{
                          padding: "1rem",
                          fontWeight: "bold",
                          borderBottom:
                            tab === "json" ? "2px solid #0894D5" : "none",
                          color: tab === "json" ? "#0894D5" : "inherit",
                        }}
                        onClick={() => setTab("json")}
                      >
                        JSON
                      </div>
                      <div
                        style={{
                          padding: "1rem",
                          fontWeight: "bold",
                          borderBottom:
                            tab === "rule" ? "2px solid #0894D5" : "none",
                          color: tab === "rule" ? "#0894D5" : "inherit",
                        }}
                        onClick={() => setTab("rule")}
                      >
                        Rule
                      </div>
                    </div>

                    {showTab(item)}
                  </div>
                </td>
              </tr>
            ) : (
              ""
            )}
          </>
        );
      });
    } catch (e) {
      alert(e.message);
    }
  };

  const showAllAlertsPagination = () => {
    const pageNumbers = [];
    const totalPosts = allAlertsData.length;

    for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
      pageNumbers.push(i);
    }

    const pagination = (pageNumbers) => {
      setAllAlertsCurrentPage(pageNumbers);
    };

    return (
      <nav>
        <ul className="pagination">
          {pageNumbers.map((number) => (
            <li
              key={number}
              className={
                allAlertsCurrentPage === number
                  ? "page-item active"
                  : "page-item"
              }
            >
              <button onClick={() => pagination(number)} className="page-link">
                {number}
              </button>
            </li>
          ))}
        </ul>
      </nav>
    );
  };

  return (
    <>
      <UserNavbar />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "1rem",
          fontSize: "1.1rem",
        }}
      >
        {showAlertType()} logs of {node} (last &nbsp;
        {timeFrame
          .replaceAll("now", "")
          .replaceAll("=", "")
          .replace("from", "")
          .replace("to", "")
          .replace("&", "")
          .replace("-", "")
          .replace("h", " hour(s)")
          .replace("d", " days")}
        )
      </div>

      <div class="table-wrap" style={{ marginTop: "0.5rem" }}>
        <table
          style={{
            borderSpacing: "inherit",
            width: "100%",
          }}
          id="data_table"
        >
          <thead
            style={{
              position: "static",
              top: 0,
            }}
          >
            <tr style={{ color: "black" }}>
              <th></th>
              <th>Time</th>
              {/* <th>Agent</th>
              <th>Agent Name</th> */}
              <th>Groups</th>
              <th>Full Log</th>
              <th>Description</th>
              <th>Level</th>
              <th>Rule ID</th>
            </tr>
          </thead>
          <tbody>{showAllAlertsData()}</tbody>
        </table>
      </div>
      <div>{showAllAlertsPagination()}</div>
    </>
  );
};

export default SystemLogs;
