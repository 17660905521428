import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import logoheader from "../Images/logo-white.webp";
import logofooter from "../Images/logo-blue.webp";
import { useDashboard } from "../contexts/DashboardContext";
import "../App.css";

const SignInPage = () => {
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });
  const [error, setError] = useState("");
  const { setAdmin } = useDashboard();
  const navigate = useNavigate();

  useEffect(() => {
    Cookies.remove("token");
    Cookies.remove("customerid");
    Cookies.remove("username");
    Cookies.remove("customerkey");
    Cookies.remove("role");
    setAdmin(false);
  }, []);

  const handleLogin = (e) => {
    e.preventDefault();
    const { username, password } = credentials;
    axios
      .post("/api/token", {
        username,
        password,
      })
      .then((res) => {
        Cookies.set("token", res.data.token);
        Cookies.set("customerid", res.data.customerid);
        Cookies.set("username", res.data.username);
        Cookies.set("customerkey", res.data.key);
        Cookies.set("role", res.data.role);
        setCredentials({
          username: "",
          password: "",
        });
        navigate("/dashboard");
      })
      .catch((err) => {
        setError("Invalid Credentials");
        setTimeout(() => {
          setError("");
        }, 1000);
      });
  };

  const handleLoginChange = (e) => {
    e.preventDefault();
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  return (
    <>
      <div className="container">
        <img
          src={logoheader}
          className="logo"
          onClick={() => navigate("/")}
        ></img>
        <ul>
          <li>
            <a href="#">Features</a>&nbsp;
            <i className="fa-solid fa-caret-down"></i>
            <div className="dropdown-menu">
              <ul>
                <li>
                  <a href="#">Feat 1</a>
                </li>
                <li>
                  <a href="#">Feat 2</a>
                </li>
                <li>
                  <a href="#">Feat 3</a>
                </li>
                <li>
                  <a href="#">Feat 4</a>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <a href="#">About Us</a>
          </li>
          <li>
            <a href="#">Pricing</a>
          </li>
          <li>
            <a href="#">Blog</a>
          </li>
        </ul>

        <div className="buttons">
          <button className="btn1">Sign In</button>
          <button className="btn2">Sign Up</button>
        </div>
      </div>

      <div class="main_body">
        <div class="contentt">
          <div class="form">
            <h2>Sign In</h2>
            <div class="form-element">
              <label for="username">Username</label>
              <input
                type="text"
                placeholder="Username"
                name="username"
                id="username"
                value={credentials.username}
                onChange={handleLoginChange}
                required
              ></input>
            </div>
            <div class="form-element">
              <label for="password">Password</label>
              <input
                type="password"
                placeholder="Password"
                name="password"
                id="password"
                value={credentials.password}
                onChange={handleLoginChange}
                required
              ></input>
            </div>
            {error}
          </div>

          <div class="form-buttons">
            <button type="submit" onClick={handleLogin} class="btn3">
              Sign In
            </button>
          </div>
        </div>
        {/* <div class="content-right">
          <img
            src="https://static.wixstatic.com/media/40a49a_fa4a25a58e3b460aabe9474cdaf372d1~mv2.jpg/v1/fill/w_950,h_536,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/engineer-constructor-designer-architect-creating-new-component-cad-program-working-busines.jpg"
            alt=""
          ></img>
        </div> */}
      </div>

      <footer>
        <div className="container_flex">
          <img src={logofooter}></img>
          <div className="left_foot">
            <div className="link-col">
              <h3>Product</h3>
              <br></br>
              <a href="#">Features</a> <br></br>
              <br></br>
              <a href="#">Pricing</a>
            </div>
            <div className="link-col">
              <h3>Resources</h3>
              <br></br>
              <a href="#">Blog</a>
              <br></br>
              <br></br>
              <a href="#">User guides</a>
              <br></br>
              <br></br>
              <a href="#">Webinars</a>
            </div>
          </div>
          <div className="right_foot">
            <div className="link-col">
              <h3>Company</h3>
              <br></br>
              <a href="#">About</a>
              <br></br>
              <br></br>
              <a href="#">Join Us</a>
            </div>
            <div className="link-col">
              <h3>Support</h3>
              <br></br>
              <a href="#">Help Centre</a>
              <br></br>
              <br></br>
              <a href="#">Chat support</a>
            </div>
          </div>
        </div>
      </footer>
      <div className="subfooter">
        <div className="copyright">
          <i className="fa-solid fa-copyright"></i>&nbsp;
          <p>2023 by SecurWeave</p>
          {/* <ul>
            <li>Privacy</li>
            <li>Terms</li>
            <li>Sitemap</li>
          </ul> */}
        </div>
        <div className="social-handles">
          <a
            target="_blank"
            href="https://www.linkedin.com/company/securweave-research-labs-pvt-ltd/"
            alt="Linkedin"
          >
            <i className="fa-brands fa-linkedin"></i>
          </a>
          <a
            target="_blank"
            href="https://www.youtube.com/channel/UCMgFldw6VY1fW0Qz_2hvQPg/featured"
            alt=""
          >
            <div className="yt">
              <i className="fa-brands fa-youtube"></i>
            </div>
          </a>
          <a
            target="_blank"
            href="https://mobile.twitter.com/securweave"
            alt="Twitter"
          >
            <i className="fa-brands fa-square-twitter"></i>
          </a>
        </div>
      </div>
    </>
  );
};

export default SignInPage;
