import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import UserNavbar from "./UserNavbar";
import SideBox from "./SideBox";
import LicenseInformation from "./LicenseInformation";
import SystemTable from "./SystemTable";
import Cookies from "js-cookie";
import { useDashboard } from "../contexts/DashboardContext";
import "../App.css";

const Configuration = () => {
  const token = Cookies.get("token");
  const customerid = Cookies.get("customerid");
  const customerkey = Cookies.get("customerkey");
  const username = Cookies.get("username");
  const { licenseInfo } = useDashboard();
  const [credentials, setCredentials] = useState({
    agentname: "",
    agentip: "",
    agentgroup: "",
    agentlocation: "",
  });
  const [error, setError] = useState("");
  const { agentsList, setAgentsList } = useDashboard();
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate("/signin");
    } else {
      axios
        .get("/api/token/isLoggedIn", {
          headers: {
            "x-auth-token": token,
          },
        })
        .then((res) => console.log(res.data))
        .catch(() => navigate("/signin"));
    }
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    const { agentname, agentip, agentgroup, agentlocation } = credentials;
    axios
      .post(
        "/api/system/addagent",
        {
          customerid,
          agentname: `${customerkey}-${agentname}`,
          agentip,
          agentgroup,
          agentlocation,
          customername: username,
        },
        {
          headers: {
            "x-auth-token": token,
          },
        }
      )
      .then((res) => {
        if (res.data.message) {
          if (res.data.message != "New Agent Added successfully") {
            setError(res.data.message);
            setTimeout(() => {
              setError("");
            }, 1000);
          } else {
            setCredentials({
              agentname: "",
              agentip: "",
              agentgroup: "",
              agentlocation: "",
            });

            setAgentsList([
              ...agentsList,
              {
                agentgroup,
                agentid: res.data.id,
                agentip,
                agentkey: res.data.key,
                agentlocation,
                agentname: `${customerkey}-${agentname}`,
              },
            ]);

            myfunt();
          }
        } else {
          setError(res.data);
          setTimeout(() => {
            setError("");
          }, 1500);
        }
      })
      .catch((err) => {
        console.log(err);
        setTimeout(() => {
          setError("");
        }, 1500);
      });
  };
  const handleChange = (e) => {
    e.preventDefault();
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  function myfunto() {
    document.getElementById("popup").style.display = "block";
    document.querySelector(".btn4").style.display = "none";
    document.getElementById("mainn_body").style.opacity = "0.1";
    document.getElementById("data_table").style.opacity = "0.1";
  }

  function myfunt() {
    document.getElementById("popup").style.display = "none";
    document.querySelector(".btn4").style.display = "block";
    document.getElementById("mainn_body").style.opacity = "1.0";
    document.getElementById("data_table").style.opacity = "1.0";
  }
  return (
    <>
      <UserNavbar />

      <div class="mainn_body" id="mainn_body">
        <SideBox />
        <LicenseInformation />
      </div>

      <SystemTable />

      <div className="popup" id="popup">
        <div className="close-btn" onClick={myfunt}>
          &times;
        </div>
        <form className="form" onSubmit={handleSubmit}>
          <h2>Add New System</h2>
          <div className="form-element">
            <label for="name">Name</label>
            <input
              type="text"
              id="name"
              name="agentname"
              placeholder="Enter system's name"
              value={credentials.agentname}
              onChange={handleChange}
              required
            ></input>
          </div>
          <div className="form-element">
            <label for="ip">IP ADDR</label>
            <input
              type="text"
              id="ip"
              placeholder="Enter system's IP"
              name="agentip"
              value={credentials.agentip}
              onChange={handleChange}
              required
            ></input>
          </div>
          <div className="form-element">
            <label for="group">Group</label>
            <input
              type="text"
              id="group"
              name="agentgroup"
              placeholder="Enter group"
              value={credentials.agentgroup}
              onChange={handleChange}
              required
            ></input>
          </div>
          <div className="form-element">
            <label for="location">Location</label>
            <input
              type="text"
              id="location"
              name="agentlocation"
              placeholder="Enter location"
              value={credentials.agentlocation}
              onChange={handleChange}
              required
            ></input>
          </div>
          <div className="form-buttons">
            <button className="btn1_1" onClick={myfunt}>
              Cancel
            </button>
            <button className="btn1_2">ADD</button>
          </div>
        </form>
        <div style={{ textAlign: "center", color: "red", fontSize: "medium" }}>
          {error}
        </div>
      </div>
      {licenseInfo[0]?.available > 0 ? (
        <button class="btn4" type="button" onClick={myfunto}>
          Add System
        </button>
      ) : (
        ""
      )}
    </>
  );
};

export default Configuration;
